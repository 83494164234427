import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faWrench } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { PropertyList, ImagesResponse } from '../../api/type';
import moment from 'moment';
import { fetchImages } from '../../api/request';
import xIcon from '../assets/x.svg';
import homeDark from '../assets/home-dark.svg';

interface JobDetailSidebarProps {
  onClose: () => void;
  sideBar: boolean;
  jobDetail: {
    id: string;
    jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
    status: string;
    date: string;
    startDate: string;
    endDate: string;
  };
  property: PropertyList | undefined;
}

const JobDetailSidebar: React.FC<JobDetailSidebarProps> = ({ onClose, sideBar, jobDetail, property }) => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await fetchImages(jobDetail.id);
        setImages(response.data || []);
      } catch (error) {
        setError('Error fetching images.');
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, [jobDetail.id]);

  const jobTypeIcons: Record<'cleaning' | 'maintenance', IconDefinition> = {
    cleaning: faBroom,
    maintenance: faWrench,
  };

  const startTime = moment(jobDetail.startDate);
  const endTime = moment(jobDetail.endDate);
  const duration = moment.duration(endTime.diff(startTime));
  const durationHours = Math.floor(duration.asHours());
  const durationMinutes = duration.minutes();

  return (
    <div className={`sidebar ${sideBar ? 'open' : ''}`}>
      <div className="sidebar-header">
        <h2>{jobDetail.jobType === 'Cleaning' ? 'Cleaning Job' : 'Maintenance Job'}</h2>
        <div className="sidebar-header-icons">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
        </div>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-content-job">
          <div>
            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Status</div>
                <div className="booking-value ongoing">Ongoing</div>
              </div>
            </div>

            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Date</div>
                <div className="booking-value">{moment(jobDetail.startDate).format('MMM D, YYYY')}</div>
              </div>
            </div>

            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Time Started</div>
                <div className="booking-value">{moment(jobDetail.startDate).format('h:mm A')}</div>
              </div>
            </div>

            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Time Finished</div>
                <div className="booking-value">{moment(jobDetail.endDate).format('h:mm A')}</div>
              </div>
            </div>

            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Duration</div>
                <div className="booking-value">{`${durationHours} hour${durationHours !== 1 ? 's' : ''} ${durationMinutes} minute${durationMinutes !== 1 ? 's' : ''}`}</div>
              </div>
            </div>

            <div className='property-row mt-1'>
              <div className="property-nou">
                <div className={property?.image ? "listing-image" : "listing-image-none"}>
                  <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
                </div>
                <div>
                  <h3>{property?.name}</h3>
                  <p>{property?.address}</p>
                </div>
              </div>
            </div>

            <div className='attachments'>
              <h3>Attachments</h3>
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : images.length > 0 ? (
                <div className='attachment-list'>
                  {images.map((image, index) => (
                    <div className='attachment' key={index}>
                      <img src={image} alt="Attachment" />
                    </div>
                  ))}
                </div>
              ) : (
                <div className='booking-label'>No Attachments Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailSidebar;
