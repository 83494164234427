import React from 'react';
import { PropertyList } from '../api/type';
import homeDark from './assets/home-dark.svg';

interface ListingProps {
  listing: PropertyList;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const Listing: React.FC<ListingProps> = ({ listing }) => {
  return (
    <div className="listing pointer" onClick={() => window.open(`https://mypropertystack.io/property_details/${listing.id}`, '_blank')}>
      <div className='listing-container'>
        <div className={listing.image ? "listing-image" : "listing-image-none"}>
          <img src={listing.image || homeDark} alt={listing.name || 'Property'} />
        </div>

        <div>
          <h3>{truncateText(listing.name || '', 30)}</h3>
          <p>{truncateText(listing.address || '', 70)}</p>
        </div>
      </div>
    </div>
  );
};

export default Listing;
