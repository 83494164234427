import React, { useState, useRef, useEffect } from 'react';
import { CalendarPMS, PropertyList } from '../api/type';
import moment from 'moment';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
import BookingSidebar from './sidebars/BookingSidebar';
import { ReservationHoverInfoResponse } from '../api/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import calendarLight from './assets/calendar.svg';
import checkCircle from './assets/check-circle.svg';
import cleaningIcon from './assets/cleaning-dark.png';
import cleaningLightIcon from './assets/cleaning.png';
import maintenanceIcon from './assets/maintenance-2.png';
import maintenanceLightIcon from './assets/maintenance.png';
import nightIcon from './assets/night.svg';
import userIcon from './assets/user-small.svg';
import improvementIcon from './assets/settings.png';
import improvementLightIcon from './assets/settings-light.png';
import taskIcon from './assets/file.png';
import taskLightIcon from './assets/file-light.png';
import contactIcon from './assets/phone.png';
import contactLightIcon from './assets/phone-light.png';
import airbnbImage from './assets/airbnb.svg';
import ownerBlockImage from './assets/ownerblock.png';
import bookingImage from './assets/booking.svg';
import directBookingImage from './assets/direct.png';
import vrboImage from './assets/vrbo.svg';
import homeDark from './assets/home-dark.svg';
import clock from './assets/clock-small.svg';
import { fetchReservationHoverInfo } from '../api/request';

interface Job {
  id: string;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface DayCellProps {
  date: string;
  listing: {
    calendarPMSList: CalendarPMS[];
    calendarPropertyId: string;
    propertyAddress: string | null;
    propertyTitle: string | null;
  };
  property: PropertyList | undefined;
  onBookingClick: (property: PropertyList | undefined, booking: CalendarPMS) => void;
  onJobClick: (property: PropertyList | undefined, date: string) => void;
  onReservationClick: (property: PropertyList | undefined, date: string) => void;
  onJobDetailClick: (job: Job, property: PropertyList | undefined) => void;
  isInRange: boolean;
}

const DayCell: React.FC<DayCellProps> = ({ date, listing, property, onBookingClick, onJobClick, onReservationClick, onJobDetailClick, isInRange }) => {
  const booking = listing.calendarPMSList.find(booking => booking.date === date);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [isMoreDetailSidebarOpen, setIsMoreDetailSidebarOpen] = useState(false);
  const [jobTooltipVisible, setJobTooltipVisible] = useState(false);
  const [jobTooltipPosition, setJobTooltipPosition] = useState({ top: 0, left: 0 });

  const [currentJobs, setCurrentJobs] = useState<Job[]>([]);
  const normalizedDate = moment(date).format('YYYY-MM-DD');
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [tooltipHoverTimeout, setTooltipHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [jobTooltipHoverTimeout, setJobTooltipHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [hoverInfo, setHoverInfo] = useState<ReservationHoverInfoResponse | null>(null);
  const [loadingHoverInfo, setLoadingHoverInfo] = useState(false);
  const [cachedHoverInfo, setCachedHoverInfo] = useState<{ [key: string]: ReservationHoverInfoResponse | null }>({});

  useEffect(() => {
    if (tooltipVisible && booking?.reservationPMS?.id) {
      fetchHoverInfo(booking.reservationPMS.id);
    }
  }, [tooltipVisible, booking?.reservationPMS?.id]);

  const fetchHoverInfo = async (reservationId: string) => {
    if (cachedHoverInfo[reservationId]) {
      setHoverInfo(cachedHoverInfo[reservationId]);
      setLoadingHoverInfo(false);
      return;
    }
    try {
      setLoadingHoverInfo(true);
      const data = await fetchReservationHoverInfo(reservationId);
      setHoverInfo(data);
      setCachedHoverInfo(prev => ({ ...prev, [reservationId]: data }));
    } catch (error) {
      console.error('Error fetching hover info:', error);
    } finally {
      setLoadingHoverInfo(false);
    }
  };



  const jobList = listing.calendarPMSList
    .filter(booking => booking.jobList && booking.jobList.length > 0)
    .flatMap(booking => booking.jobList)
    .filter(job => moment(job.startDate).format('YYYY-MM-DD') === normalizedDate || moment(job.endDate).format('YYYY-MM-DD') === normalizedDate);

  const handleMouseEnter = (event: React.MouseEvent) => {
    if (booking && booking.reservationPMS && event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          let top = 68;
          let left = 30;
          if (rect.left > 1200) {
            left = -295;
            top = -20;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -295;
            top = -20;
          }

          if (rect.top > 550) {
            top = booking?.reservationPMS?.otaName === 'Manual Block' || 'After Block' || 'Before Block' ? -250 : -403;
          }

          setTooltipPosition({ top: top, left: left });
          setTooltipVisible(true);
        }
      }, 400);
      setTooltipHoverTimeout(timeoutId);
    }
  };
  const handleMouseLeave = () => {
    if (tooltipHoverTimeout) {
      clearTimeout(tooltipHoverTimeout);
      setTooltipHoverTimeout(null);
    }
    setTooltipVisible(false);
  };

  const handleJobMouseEnter = (event: React.MouseEvent, job: Job) => {
    if (event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          setTooltipVisible(false);
          let top = 68;
          let left = 30;
          if (rect.left > 1200) {
            left = -310;
            top = -170;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -310;
            top = -50;
          }
          if (rect.top > 250 && rect.top < 425) {
            top = 60;
          }
          if (rect.top > 450) {
            top = -286;
          }
          setJobTooltipVisible(true);
          setJobTooltipPosition({ top: top, left: left });
          setCurrentJobs([job]);
        }
      }, 400);
      setJobTooltipHoverTimeout(timeoutId);
    }
  };

  const handleJobMouseLeave = () => {
    if (jobTooltipHoverTimeout) {
      clearTimeout(jobTooltipHoverTimeout);
      setTooltipHoverTimeout(null);
    }
    setCurrentJobs([]);
    setJobTooltipVisible(false);
  };

  const handleMoreJobsMouseEnter = (event: React.MouseEvent) => {
    if (event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          setJobTooltipVisible(true);
          let top = -50;
          let left = jobList.length === 2 ? 85 : 120;

          if (rect.left > 1200) {
            left = -310;
            top = -170;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -310;
            top = -50;
          }
          if (rect.top > 425) {
            top = -270;
          }
          setJobTooltipPosition({ top: top, left: left });
          setCurrentJobs(jobList);
        }
      }, 400);
      setJobTooltipHoverTimeout(timeoutId);
    }
  };
  const handleMoreJobsMouseLeave = () => {
    if (jobTooltipHoverTimeout) {
      clearTimeout(jobTooltipHoverTimeout);
      setTooltipHoverTimeout(null);
    }
    // setCurrentJobs([]);
    setJobTooltipVisible(false);
  };

  const handleCellEnter = () => {
    const timeoutId = setTimeout(() => {
      handleClick();
    }, 400);
    setHoverTimeout(timeoutId);
  };

  const handleCellLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    handleLeave();
  };


  const handleClick = () => {
    setShowAddOptions(true);
  };

  const handleLeave = () => {
    setShowAddOptions(false);
  };

  const openBookingSidebar = (property: PropertyList | undefined, booking: CalendarPMS) => {
    if (booking) {
      onBookingClick(property, booking);
    }
  };

  const openCreateJob = (property: PropertyList | undefined, date: string) => {
    console.log("open job for ", property);
    console.log("on date", date);
    onJobClick(property, date);
  };

  const openCreateReservation = (property: PropertyList | undefined, date: string) => {
    console.log("open reservation for ", property);
    console.log("on date", date);
    onReservationClick(property, date)
  };

  const openJobDetailSidebar = (job: Job, property: PropertyList | undefined) => {
    if (job) {
      onJobDetailClick(job, property);
    }
  };

  const openMoreDetailSidebar = () => {
    setCurrentJobs(jobList);
    setIsMoreDetailSidebarOpen(true);
  };

  const closeMoreDetailSidebar = () => {
    setIsMoreDetailSidebarOpen(false);
    setCurrentJobs([]);
  };

  const getJobIcon = (type: string) => {
    switch (type) {
      case 'Cleaning':
        return cleaningIcon;
      case 'Maintenance':
        return maintenanceIcon;
      case 'Task':
        return taskIcon;
      case 'Improvement':
        return improvementIcon;
      case 'Contact':
        return contactIcon;
      default:
        return improvementIcon;
    }
  };

  const getJobLightIcon = (type: string) => {
    switch (type) {
      case 'Cleaning':
        return cleaningLightIcon;
      case 'Maintenance':
        return maintenanceLightIcon;
      case 'Task':
        return taskLightIcon;
      case 'Improvement':
        return improvementLightIcon;
      case 'Contact':
        return contactLightIcon;
      default:
        return improvementLightIcon;
    }
  };

  const getImageForBookingChannel = (type: string) => {
    switch (type) {
      case 'Airbnb':
        return airbnbImage;
      case 'Owners Block':
        return ownerBlockImage;
      case 'Booking.com':
        return bookingImage;
      case 'Direct':
        return directBookingImage;
      case 'VRBO':
        return vrboImage;
      default:
        return directBookingImage;
    }
  };

  const arrivalDate = booking?.reservationPMS?.arrivalDate ? moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD') : null;

  const departureDate = booking?.reservationPMS?.departureDate ? moment(booking.reservationPMS.departureDate).utc().format('YYYY-MM-DD') : null;


  const isBookingStart = booking?.reservationPMS && moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD') === normalizedDate;
  const isBookingEnd = booking?.reservationPMS && moment(booking.reservationPMS.departureDate).utc().format('YYYY-MM-DD') === normalizedDate && !moment(booking.reservationPMS.arrivalDate).utc().isSame(booking.reservationPMS.departureDate, 'day');
  const sameDayDeparture = booking?.reservationPMS && moment(booking.reservationPMS.arrivalDate).isSame(booking.reservationPMS.departureDate, 'day');

  const hasMatchingArrivalDeparture = listing.calendarPMSList.some(b => {
    const matchesArrivalDeparture = b.reservationPMS &&
      b.reservationPMS.departureDate === booking?.reservationPMS?.arrivalDate;

    const blockBeforeDeparture = listing.calendarPMSList.some(block =>
      block.reservationPMS &&
      block.reservationPMS.otaName === "Before Block" &&
      moment(block.reservationPMS.departureDate).isBefore(booking?.reservationPMS?.departureDate, 'day')
    );
    return matchesArrivalDeparture && !blockBeforeDeparture;
  });


  const isManualOrOwnerBlock = booking?.reservationPMS?.otaName === 'Manual Block' || booking?.reservationPMS?.otaName === 'Advance Notice' || booking?.reservationPMS?.otaName === 'After Block' || booking?.reservationPMS?.otaName === 'Before Block' || booking?.reservationPMS?.otaName === 'Rolling window';

  const hasMatchingDepartureAndArrival = () => {
    if (!booking?.reservationPMS?.arrivalDate) return false;
    const arrivalDate = moment(booking.reservationPMS.arrivalDate);
    const matchingBooking = listing.calendarPMSList.find(b =>
      b.reservationPMS &&
      moment(b.reservationPMS.departureDate).isSame(arrivalDate, 'day')
    );
    return !!matchingBooking;
  };

  const hasMatchingBlockAndBooking = listing.calendarPMSList.some((b, index, array) => {
    const currentReservation = b.reservationPMS;
    const isCurrentBlock = currentReservation && currentReservation.otaName === "Before Block";


    const nextItem = array[index + 1];
    if (!nextItem || !nextItem.reservationPMS) return false;

    const nextReservation = nextItem.reservationPMS;
    const isNextBooking = (nextReservation.otaName === "Direct" || nextReservation.otaName === "Airbnb" || nextReservation.otaName === "VRBO" || nextReservation.otaName === "Booking") &&
      nextReservation.arrivalDate === currentReservation?.departureDate;

    return isCurrentBlock && isNextBooking;
  });

  const hasMatchingDepartureAndArrivalNotManual = () => {
    if (!booking?.reservationPMS?.arrivalDate) return false;
    const arrivalDate = moment(booking.reservationPMS.arrivalDate);
    const matchingBooking = listing.calendarPMSList.find(b =>
      b.reservationPMS &&
      moment(b.reservationPMS.departureDate).isSame(arrivalDate, 'day') &&
      b.reservationPMS.otaName !== 'Manual Block' &&
      b.reservationPMS.otaName !== 'Advance Notice' &&
      b.reservationPMS.otaName !== 'After Block' &&
      b.reservationPMS.otaName !== 'Before Block'
    );
    return !!matchingBooking;
  };

  const hasMatchingDepartureAndArrivalManual = () => {
    if (!booking?.reservationPMS?.arrivalDate || booking.reservationPMS.otaName !== 'Manual Block') return false;
    const arrivalDate = moment(booking.reservationPMS.arrivalDate);
    const matchingBooking = listing.calendarPMSList.find(b =>
      b.reservationPMS &&
      moment(b.reservationPMS.departureDate).isSame(arrivalDate, 'day') &&
      (b.reservationPMS.otaName === 'Manual Block' || b.reservationPMS.otaName === 'Advance Notice' || b.reservationPMS.otaName === 'After Block' || b.reservationPMS.otaName === 'Before Block')
    );
    return !!matchingBooking;
  };

  return (
    // <div
    //   className="day-cell"
    //   onMouseEnter={handleCellEnter}
    //   onMouseLeave={handleCellLeave}
    // >
    <div
      className={`day-cell ${isInRange ? '' : 'day-cell-disabled'}`}  // Add conditional class
      onMouseEnter={isInRange ? handleCellEnter : undefined}  // Disable hover events if not in range
      onMouseLeave={isInRange ? handleCellLeave : undefined}  // Disable hover events if not in range
      onClick={isInRange ? handleClick : undefined}  // Disable clicks if not in range
    >


      {booking && booking.reservationPMS ? (
        <>
          {jobList.length > 0 && (
            <div className='job-container'>
              <div className="jobs">
                {jobList.length === 1 ? (
                  <div
                    key={0}
                    className="job-icon"
                    onMouseEnter={(event) => handleJobMouseEnter(event, jobList[0])}
                    onMouseLeave={handleJobMouseLeave}
                    onClick={() => openJobDetailSidebar(jobList[0], property)}
                  >
                    <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                  </div>
                ) : (
                  jobList.length > 1 && (
                    <>
                      {jobList.slice(0, 1).map((job, index) => (
                        <div
                          key={index}
                          className="job-icon"
                          onMouseEnter={(event) => handleJobMouseEnter(event, job)}
                          onMouseLeave={handleJobMouseLeave}
                          onClick={() => openJobDetailSidebar(job, property)}
                        >
                          <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                        </div>
                      ))}
                    </>
                  )
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        jobList.length > 0 && (
          <div className='jobs-container'>
            <div className="jobs" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {jobList.length === 1 ? (
                <div
                  className="job-icon"
                  onMouseEnter={(event) => handleJobMouseEnter(event, jobList[0])}
                  onMouseLeave={handleJobMouseLeave}
                  onClick={() => openJobDetailSidebar(jobList[0], property)}
                >
                  <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                </div>
              ) : (
                <>
                  {jobList.slice(0, jobList.length === 3 ? 3 : 2).map((job, index) => (
                    <div
                      key={index}
                      className="job-icon"
                      onMouseEnter={handleMoreJobsMouseEnter}
                      onMouseLeave={handleMoreJobsMouseLeave}
                      onClick={openMoreDetailSidebar}
                    >
                      <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                    </div>
                  ))}
                  {jobList.length > 3 && (
                    <>
                      <div
                        className="job-icon ellipsis"
                        onMouseEnter={handleMoreJobsMouseEnter}
                        onMouseLeave={handleMoreJobsMouseLeave}
                        onClick={openMoreDetailSidebar}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </div>
                      <div className="more">
                        <span className="more-jobs">{jobList.length - 2}</span>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )
      )}
      {booking ? (
        booking.reservationPMS ? (
          <>
            {!sameDayDeparture && hasMatchingBlockAndBooking && (
              <div className='manual-end-and-manual'></div>
            )}
            {!sameDayDeparture && hasMatchingArrivalDeparture && (
              <div className='booking-end-and-job'></div>
            )}
            {isManualOrOwnerBlock ? (
              <>
                {!sameDayDeparture && hasMatchingDepartureAndArrivalNotManual() && (
                  <div className='booking-end-and-job'></div>
                )}
                {sameDayDeparture && hasMatchingDepartureAndArrivalNotManual() && (
                  <div className='booking-end-and-job'></div>
                )}
                {!sameDayDeparture && hasMatchingDepartureAndArrivalManual() && (
                  <div className='manual-end-and-manual'></div>
                )}

                <div
                  className={`manual-block ${sameDayDeparture ? 'booking-same-day' : isBookingStart ? (jobList.length > 0 ? 'job-and-booking-start' : 'booking-start') : ''} ${isBookingEnd ? 'booking-end' : ''}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => openBookingSidebar(property,
                    booking
                  )}
                ></div>

                {isBookingEnd && showAddOptions && (
                  <div className="add-options">
                    <p className="margin-left-35" onClick={() => openCreateJob(property, date)}>+ Job</p>
                    <div className="separator-small-line margin-left-35">
                      <span>or</span>
                    </div>
                    <p className="margin-left-35" onClick={() => openCreateReservation(property, date)}>+ Reservation</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`booking ${sameDayDeparture ? 'booking-same-day' : isBookingStart ? (jobList.length > 0 ? 'job-and-booking-start' : 'booking-start') : ''} ${isBookingEnd ? 'booking-end' : ''}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => openBookingSidebar(property,
                    booking
                  )}
                >
                  {isBookingStart && (
                    <div className="booking-start-content">
                      <div className="circle-image">
                        <img src={getImageForBookingChannel(booking.reservationPMS.otaName ? booking.reservationPMS.otaName : '')} alt="Guest" />
                      </div>
                      <div className="booking-details">
                        <p className="guest-name">
                          {booking.reservationPMS.guestFullName.split(' ').map((part, index) => {
                            if (index === 0 && part.length >= 8) {
                              return part.slice(0, 8);
                            } else if (index === 1) {
                              return part[0] + '.';
                            }
                            return part;
                          }).slice(0, 2).join(' ')}
                        </p>
                        <p className="guest-count-label">{booking.reservationPMS.totalGuests} Guests</p>
                        <p className="stay-details">{booking.reservationPMS.totalNights} Nights</p>
                      </div>
                    </div>
                  )}
                </div>
                {isBookingEnd && showAddOptions && (
                  <div className="add-options">
                    <p className="margin-left-35" onClick={() => openCreateJob(property, date)}>+ Job</p>
                    <div className="separator-small-line margin-left-35">
                      <span>or</span>
                    </div>
                    <p className="margin-left-35" onClick={() => openCreateReservation(property, date)}>+ Reservation</p>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          jobList.length >= 4 ? null : (
            showAddOptions ? (
              jobList.length === 1 ? (
                <div className="add-options ">
                  <p className="margin-left-30" onClick={() => openCreateJob(property, date)}>+Job</p>
                  <div className="separator-small-line margin-left-30">
                    <span>or</span>
                  </div>
                  <p className="margin-left-30" onClick={() => openCreateReservation(property, date)}>+ Reservation</p>
                </div>
              ) : (
                <div className={`add-options ${jobList.length >= 2 ? 'z-index-10' : ''}`}>
                  <p onClick={() => openCreateJob(property, date)}>+Add Job</p>
                  <div className="separator">
                    <span>or</span>
                  </div>
                  <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
                </div>
              )
            ) : (
              <div className="availability">
                <p className="price">${Math.round(booking.price)}</p>
                <p className="stay"><span className='mx-half-right'><img src={nightIcon} alt='night-icon' height={8} width={8} /></span>{booking.minStay}</p>
              </div>
            )
          )

        )
      ) : (
        jobList.length >= 4 ? null : (
          showAddOptions ? (
            <div className={`add-options ${jobList.length >= 2 ? 'z-index-10' : ''}`}>
              <p onClick={() => openCreateJob(property, date)}>+Add Job</p>
              <div className="separator">
                <span>or</span>
              </div>
              <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
            </div >
          ) : (
            <div className="availability">
              <p className="stay"> <img src={nightIcon} alt='night-icon' height={8} width={8} /></p>
            </div>
          )
        )
      )}

      {
        tooltipVisible && booking && booking.reservationPMS && property && (
          <div className="tooltip" style={{ top: tooltipPosition.top, left: tooltipPosition.left }} onClick={() => setTooltipVisible(false)}>
            <div className="tooltip-content">
              <div className="reservation-tooltip-container">
                <div className="reservation-tooltip-info">
                  <div className="reservation-icon"><img src={calendarLight} alt="Calendar Icon" /></div>
                  <div className="reservation-value">Reservation - {booking.reservationPMS.otaName}</div>
                </div>
                <div className="reservation-tooltip-info">
                  <div className="confirmed-icon"><img src={checkCircle} alt="Calendar Icon" /></div>
                  <div className="reservation-value confirmed">{booking.reservationPMS.reservationStatus}</div>
                </div>
              </div>
              <div className="reservation-info">
                <div className='reservation-container'>
                  <div className="reservation-dates">
                    <p>{moment(booking.reservationPMS.arrivalDate).format('MMM D YYYY')} - {moment(booking.reservationPMS.departureDate).format('MMM D YYYY')}</p>
                    <div className="time-info">
                      <span className='mr-3'><img src={clock} alt="Clock Icon" /> {moment(booking.reservationPMS.arrivalDate).format('h:mm A')}</span>
                      <span><img src={clock} alt="Clock Icon" /> {moment(booking.reservationPMS.departureDate).format('h:mm A')}</span>
                    </div>
                  </div>
                  {booking.reservationPMS.otaName !== 'Manual Block' && booking.reservationPMS.otaName !== 'Advance Notice' && booking.reservationPMS.otaName !== 'After Block' && booking.reservationPMS.otaName !== 'Before Block' && booking.reservationPMS.otaName !== 'Rolling window' && (
                    <>
                      <div className="reservation-tooltip-info">
                        <div className="reservation-icon"> <img src={nightIcon} alt="Half Moon Icon" className='night-icon' /></div>
                        <div className="reservation-value">{booking.reservationPMS.totalDays} Days {booking.reservationPMS.totalNights} Nights</div>
                      </div>
                      <div className="reservation-tooltip-info">
                        <div className="reservation-icon"><img src={userIcon} alt="User Small" className='user-icon' /></div>
                        <div className="reservation-value">{booking.reservationPMS.totalGuests} Guests</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='property-dark-row'>
                <div className="property-dark">
                  <div className={property.image ? "listing-image" : "listing-image-none"}>
                    <img src={property.image || homeDark} alt={property.name || 'Property'} />
                  </div>
                  <div>
                    <h3>{property.name}</h3>
                    <p>{property.address}</p>
                  </div>
                </div>
              </div>
              {
                booking.reservationPMS.otaName !== 'Manual Block' &&
                booking.reservationPMS.otaName !== 'Advance Notice' &&
                booking.reservationPMS.otaName !== 'After Block' &&
                booking.reservationPMS.otaName !== 'Before Block' &&
                booking.reservationPMS.otaName !== 'Rolling window' && (
                  <div className="amounts-container">
                    <div className="amounts">
                      <div>
                        {loadingHoverInfo ? (
                          <div className="loading-spinner-container">
                            <div className="spinner-tooltip">
                              <div className="double-bounce1"></div>
                              <div className="double-bounce2"></div>
                            </div>
                          </div>
                        ) : (
                          <p className="amount-value">${hoverInfo?.data.balanceDue ? hoverInfo.data.balanceDue : '0.00'}</p>

                        )}
                        <p className="amount-label">Balance Due</p>
                      </div>
                      <div>
                        {loadingHoverInfo ? (
                          <div className="loading-spinner-container">
                            <div className="spinner-tooltip">
                              <div className="double-bounce1"></div>
                              <div className="double-bounce2"></div>
                            </div>
                          </div>
                        ) : (
                          <p className="amount-value">${hoverInfo?.data.totalPaid ? hoverInfo.data.totalPaid : '0.00'}</p>

                        )}
                        <p className="amount-label">Paid</p>
                      </div>
                      <div>
                        {loadingHoverInfo ? (
                          <div className="loading-spinner-container">
                            <div className="spinner-tooltip">
                              <div className="double-bounce1"></div>
                              <div className="double-bounce2"></div>
                            </div>
                          </div>
                        ) : (
                          <p className="amount-value">${hoverInfo?.data.payout ? hoverInfo.data.payout : '0.00'}</p>
                        )}
                        <p className="amount-label">Payout</p>
                      </div>
                    </div>
                  </div>
                )
              }

            </div>
          </div>
        )
      }
      {
        jobTooltipVisible && currentJobs.length === 1 && property && (
          <div className="tooltip" style={{ top: jobTooltipPosition.top, left: jobTooltipPosition.left }} onClick={() => setJobTooltipVisible(false)}>
            <div className="tooltip-content">
              <div className="reservation-tooltip-container">
                <div className="reservation-tooltip-info">
                  <div className="reservation-icon"><img src={getJobLightIcon(currentJobs[0].jobType)} alt={jobList[0].jobType} /></div>
                  <div className="reservation-value">{currentJobs[0].jobType}</div>
                </div>
                <div className="reservation-tooltip-info">
                  {currentJobs[0].status === 'finished' && (
                    <div className="confirmed-icon">
                      <img src={checkCircle} alt="Check circle icon" />
                    </div>
                  )}
                  <div className={`reservation-value ${currentJobs[0].status === 'Open' ? 'ongoing' : 'confirmed'}`}>
                    {currentJobs[0].status === 'Open' ? 'On Going' : 'Confirmed'}
                  </div>
                </div>
              </div>
              <div className="reservation-info">
                <div className='reservation-container'>
                  <div className="reservation-dates">
                    <p>{moment(currentJobs[0].startDate).format('MMM D, YYYY')}</p>
                    <div className="time-info">
                      <span className='mr-3'><img src={clock} alt="Clock Icon" />{moment(currentJobs[0].startDate).format('h:mm A')} <span className='mx-1'>
                        - </span>
                        <img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].endDate).format('h:mm A')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='property-dark-row'>
                <div className="property-dark">
                  <div className={property.image ? "listing-image" : "listing-image-none"}>
                    <img src={property.image || homeDark} alt={property.name || 'Property'} />
                  </div>
                  <div>
                    <h3>{property.name}</h3>
                    <p>{property.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        jobTooltipVisible && currentJobs.length > 1 && property && (
          <div className="tooltip-more" style={{ top: jobTooltipPosition.top, left: jobTooltipPosition.left }} onClick={() => setJobTooltipVisible(false)}>
            <div className="tooltip-content">
              {currentJobs.map((job, index) => (
                <div key={index} className="reservation-tooltip-container">
                  <div className="reservation-tooltip-info">
                    <div className="reservation-icon"><img src={getJobLightIcon(job.jobType)} alt={job.jobType} /></div>
                    <div className="reservation-info-tooltip">
                      {job.status === 'finished' && (
                        <div className="confirmed-icon">
                          <img src={checkCircle} alt="Check Icon" />
                        </div>
                      )}
                      <div className={`reservation-value ${job.status === 'Open' ? 'ongoing' : 'confirmed'}`}>
                        {job.status === 'Open' ? 'On Going' : 'Confirmed'}
                      </div>
                      <div className="reservation-value-small">{job.jobType}</div>
                      <div className="reservation-value-more-small">Contractor's name</div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="reservation-info">
                <div className='reservation-container'>
                  <div className="reservation-dates">
                    <p>{moment(currentJobs[0].startDate).format('MMM D, YYYY')}</p>
                    <div className="time-info">
                      <span className='mr-3'><img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].startDate).format('h:mm A')} <span className='mx-1'>
                        - </span>
                        <img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].endDate).format('h:mm A')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='property-dark-row'>
                <div className="property-dark">
                  <div className={property.image ? "listing-image" : "listing-image-none"}>
                    <img src={property.image || homeDark} alt={property.name || 'Property'} />
                  </div>
                  <div>
                    <h3>{property.name}</h3>
                    <p>{property.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        isMoreDetailSidebarOpen && (
          <MoreJobDetailSidebar
            onClose={closeMoreDetailSidebar}
            sideBar={isMoreDetailSidebarOpen}
            jobs={currentJobs}
            property={property}
          />
        )
      }
    </div>
  );
};

export default DayCell;

