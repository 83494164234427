import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Listing from './Listing';
import Calendar from './Calendar';
import FindAvailabilityModal from './modals/FindAvailabilityModal';
import { generateDatesForMonth } from '../utils/dateUtils';
import moment from 'moment';

import {
  PropertyList,
  CalendarResponse,
  Listing as ListingType,
  CalendarPMS
} from '../api/type';
import { fetchPropertiesAvailability, fetchCalendarDataVFour } from '../api/request';

import leftIcon from './assets/arrow-left.svg';
import rightIcon from './assets/arrow-right.svg';
import { ImportantOrgContext } from '../App';
import BookingSidebar from './sidebars/BookingSidebar';
import ReservationSidebar from './sidebars/ReservationSidebar';
import JobSidebar from './sidebars/JobSidebar';
import JobDetailSidebar from './sidebars/JobDetailSidebar';
import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
import { faL } from '@fortawesome/free-solid-svg-icons';

interface Job {
  id: string;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface CalendarRef {
  scrollToCurrentDate: () => void;
  scrollToFirstDate: () => void;
  scrollToSpecificDate: (targetDate: string) => void;
}

const CalendarApp = () => {
  const importantOrgId = useContext(ImportantOrgContext);

  const [properties, setProperties] = useState<PropertyList[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<PropertyList[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dates, setDates] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM YYYY'));
  const [propertyCount, setPropertyCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarData, setCalendarData] = useState<ListingType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [loadedPages, setLoadedPages] = useState<number[]>([]);
  const loadedPagesRef = useRef<number[]>([]);
  const [limit] = useState(10);
  const [selectedRange, setSelectedRange] = useState({ startDate: '', endDate: '' });
  const MAX_CONCURRENT_REQUESTS = 1;
  const [cache, setCache] = useState<{
    [key: string]: {
      calendarData: ListingType[];
      filteredProperties: PropertyList[];
      propertyCount: number;
    };
  }>({});

  const [selectedDate, setSelectedDate] = useState<string>(moment().format("MMMM YYYY"));
  const [isFiltered, setIsFiltered] = useState(false);

  const [isBookingSidebarOpen, setIsBookingSidebarOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<CalendarPMS | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<PropertyList | undefined>(undefined);

  const [isJobSidebarOpen, setIsJobSidebarOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);

  const [isReservationSidebarOpen, setIsReservationSidebarOpen] = useState(false);

  const [isJobDetailSidebarOpen, setIsJobDetailSidebarOpen] = useState(false);

  const [needToScrollToFirstDate, setNeedToScrollToFirstDate] = useState(false);
  const [needToScrollToSpecificDate, setNeedToScrollToSpecificDate] = useState(false);
  const [specificDate, setSpecificDate] = useState<string | null>(null);


  const openBookingSidebar = (property: PropertyList | undefined, booking: CalendarPMS) => {
    closeAllSidebars();
    setSelectedBooking(booking);
    setSelectedProperty(property);
    setIsBookingSidebarOpen(true);
  };

  const closeBookingSidebar = () => {
    setSelectedBooking(null);
    setSelectedProperty(undefined);
    setIsBookingSidebarOpen(false);
  };

  const openJobSidebar = (property: PropertyList | undefined, date: string) => {
    closeAllSidebars();
    setSelectedDate(date);
    setSelectedProperty(property);
    setIsJobSidebarOpen(true);
  };

  const closeJobSidebar = () => {
    setIsJobSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  };

  const openReservationSidebar = (property: PropertyList | undefined, date: string) => {
    closeAllSidebars();
    setSelectedDate(date);
    setSelectedProperty(property);
    setIsReservationSidebarOpen(true);
  };

  const closeReservationSidebar = () => {
    setIsReservationSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  };

  const openJobDetailSidebar = (job: Job, property: PropertyList | undefined) => {
    closeAllSidebars();
    setSelectedJob(job);
    setSelectedProperty(property);
    setIsJobDetailSidebarOpen(true);
  };

  const closeJobDetailSidebar = () => {
    setIsJobDetailSidebarOpen(false);
    setSelectedJob(null);
    setSelectedProperty(undefined);
  };

  const closeAllSidebars = () => {
    if (isJobSidebarOpen) {
      closeJobSidebar();
    }
    if (isReservationSidebarOpen) {
      closeReservationSidebar();
    }
    if (isBookingSidebarOpen) {
      closeBookingSidebar();
    }
    if (isJobDetailSidebarOpen) {
      closeJobDetailSidebar();
    }
  };

  const calendarRef = useRef<CalendarRef>(null);

  const fetchCalendarDataForMonth = async (
    month: string,
    year: string,
    pageToFetch: number
  ): Promise<boolean> => {
    const monthKey = `${month} ${year}`;

    if (loadedPagesRef.current.includes(pageToFetch)) return false;

    try {
      if (pageToFetch === 1) {
        setLoading(true);
      }

      const startDate = moment(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');


      console.log(`Fetching page ${pageToFetch} for ${monthKey}`);

      const calendarResponse: CalendarResponse = await fetchCalendarDataVFour(
        startDate,
        endDate,
        importantOrgId,
        pageToFetch,
        limit
      );

      if (calendarResponse.data) {
        const { properties = [], reservations = [] } = calendarResponse.data;

        if (properties.length === 0) {
          if (pageToFetch === 1) {
            setLoading(false);
          }
          return false;
        }

        const mappedData = properties.map(property => {
          const calendarItem = reservations.find(cd => cd.calendarPropertyId === property.id);
          return {
            calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
            calendarPropertyId: property.id,
            propertyAddress: property.address,
            propertyTitle: property.name,
            jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          };
        });

        setCalendarData(prevData =>
          pageToFetch === 1 ? mappedData : [...prevData, ...mappedData]
        );
        setFilteredProperties(prevProps =>
          pageToFetch === 1 ? properties : [...prevProps, ...properties]
        );
        setProperties(prevProps =>
          pageToFetch === 1 ? properties : [...prevProps, ...properties]
        );
        setPropertyCount(prevCount =>
          pageToFetch === 1 ? properties.length : prevCount + properties.length
        );

        setLoadedPages(prevPages => {
          const newPages = [...prevPages, pageToFetch];
          loadedPagesRef.current = newPages;
          return newPages;
        });

        setCache(prevCache => {
          const existingData = prevCache[monthKey] || {
            calendarData: [],
            filteredProperties: [],
            propertyCount: 0,
          };

          return {
            ...prevCache,
            [monthKey]: {
              calendarData:
                pageToFetch === 1
                  ? mappedData
                  : [...existingData.calendarData, ...mappedData],
              filteredProperties:
                pageToFetch === 1
                  ? properties
                  : [...existingData.filteredProperties, ...properties],
              propertyCount:
                pageToFetch === 1
                  ? properties.length
                  : existingData.propertyCount + properties.length,
            },
          };
        });

        if (pageToFetch === 1) {
          setLoading(false);
        }

        return true;
      } else {
        if (pageToFetch === 1) {
          setLoading(false);
        }
        return false;
      }
    } catch (error) {
      console.error(`Error loading data for page ${pageToFetch}`, error);
      if (pageToFetch === 1) {
        setLoading(false);
      }
      return false;
    }
  };

  const fetchRemainingPages = async (monthName: string, year: string) => {
    setIsFetchingMore(true);
    let pageToFetch = 2;
    let moreData = true;

    while (moreData) {
      const promises = [];

      for (let i = 0; i < MAX_CONCURRENT_REQUESTS; i++) {
        const currentPage = pageToFetch++;
        const promise = fetchCalendarDataForMonth(monthName, year, currentPage);
        promises.push(promise);
      }

      const results = await Promise.all(promises);

      moreData = results.some(result => result === true);
    }
    setIsFetchingMore(false);
  };

  const resetFilter = () => {
    const currentMonthKey = currentMonth;
    const cachedData = cache[currentMonthKey];

    if (cachedData) {
      setCalendarData(cachedData.calendarData);
      setFilteredProperties(cachedData.filteredProperties);
      setProperties(cachedData.filteredProperties);
      setPropertyCount(cachedData.propertyCount);
    }

    setIsFiltered(false);
    setSelectedRange({ startDate: '', endDate: '' });
  };

  useEffect(() => {
    const currentYear = moment().year();
    const currentMonthIndex = moment().month();
    const monthName = moment().format('MMMM');
    const year = moment().format('YYYY');
    setDates(generateDatesForMonth(currentYear, currentMonthIndex));

    const monthKey = `${monthName} ${year}`;
    if (cache[monthKey]) {
      console.log(`Loading data for ${monthKey} from cache.`);
      const cachedData = cache[monthKey];
      setCalendarData(cachedData.calendarData);
      setFilteredProperties(cachedData.filteredProperties);
      setProperties(cachedData.filteredProperties);
      setPropertyCount(cachedData.propertyCount);
      setLoading(false);
    } else {
      setLoadedPages([]);
      loadedPagesRef.current = [];

      // Fetch the initial page
      fetchCalendarDataForMonth(monthName, year, 1).then(() => {
        // Fetch remaining pages
        fetchRemainingPages(monthName, year);
      });
    }
  }, []);

  useEffect(() => {
    if (
      calendarData.length > 0 &&
      currentMonth === moment().format('MMMM YYYY')
    ) {
      scrollToToday();
    }
  }, [calendarData, currentMonth]);

  useEffect(() => {
    if (needToScrollToFirstDate && calendarRef.current) {
      calendarRef.current.scrollToFirstDate();
      setNeedToScrollToFirstDate(false);
    }
  }, [needToScrollToFirstDate]);

  useEffect(() => {
    if (specificDate && needToScrollToSpecificDate && calendarRef.current) {
      calendarRef.current.scrollToSpecificDate(specificDate);
      setNeedToScrollToSpecificDate(false);
    }
  }, [needToScrollToSpecificDate]);


  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleMonthChange = useCallback(
    (direction: 'prev' | 'next') => {
      const newMonth = moment(currentMonth, 'MMMM YYYY')
        .add(direction === 'next' ? 1 : -1, 'month')
        .format('MMMM YYYY');
      const [monthName, year] = newMonth.split(' ');
      const monthIndex = moment().month(monthName).month();
      setDates(generateDatesForMonth(Number(year), monthIndex));
      setCurrentMonth(newMonth);

      if (moment().format('MMMM YYYY') !== newMonth) {
        setNeedToScrollToFirstDate(true);
      }

      const monthKey = `${monthName} ${year}`;
      if (cache[monthKey]) {
        console.log(`Loading data for ${monthKey} from cache.`);
        const cachedData = cache[monthKey];
        setCalendarData(cachedData.calendarData);
        setFilteredProperties(cachedData.filteredProperties);
        setProperties(cachedData.filteredProperties);
        setPropertyCount(cachedData.propertyCount);
        setLoading(false);
      } else {
        setCalendarData([]);
        setFilteredProperties([]);
        setProperties([]);
        setPropertyCount(0);
        setLoadedPages([]);
        loadedPagesRef.current = [];

        // Fetch the initial page 
        fetchCalendarDataForMonth(monthName, year, 1).then(() => {
          // Fetch remaining pages 
          fetchRemainingPages(monthName, year);
        });
      }
    },
    [currentMonth, cache]
  );

  const scrollToToday = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
    }
  }, []);

  const handleTodayClick = useCallback(() => {
    const todayMonth = moment().format('MMMM YYYY');
    if (currentMonth !== todayMonth) {
      if (calendarRef.current) {
        calendarRef.current.scrollToFirstDate();
      }
      const currentYear = moment().year();
      const currentMonthIndex = moment().month();
      const monthName = moment().format('MMMM');
      const year = moment().format('YYYY');
      setDates(generateDatesForMonth(currentYear, currentMonthIndex));
      setCurrentMonth(todayMonth);

      const monthKey = `${monthName} ${year}`;
      if (cache[monthKey]) {
        console.log(`Loading data for ${monthKey} from cache.`);
        const cachedData = cache[monthKey];
        setCalendarData(cachedData.calendarData);
        setFilteredProperties(cachedData.filteredProperties);
        setPropertyCount(cachedData.propertyCount);
        setLoading(false);
      } else {
        setCalendarData([]);
        setFilteredProperties([]);
        setProperties([]);
        setPropertyCount(0);
        setLoadedPages([]);
        loadedPagesRef.current = [];

        // Fetch the initial page 
        fetchCalendarDataForMonth(monthName, year, 1).then(() => {
          // Fetch remaining pages in the background
          fetchRemainingPages(monthName, year);
        });
      }
    } else {
      scrollToToday();
    }
  }, [currentMonth, cache]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  // const updateProperties = async (checkInDate: string, checkOutDate: string, city: string) => {
  //   try {
  //     setLoading(true);
  //     setIsFiltered(true);
  //     setSelectedRange({ startDate: checkInDate, endDate: checkOutDate });
  //     const propertiesResponse = await fetchPropertiesAvailability(importantOrgId, checkInDate, checkOutDate, city);
  //     if (propertiesResponse.data) {
  //       setProperties(propertiesResponse.data);

  //       const calendarResponse = await fetchCalendarDataVFour(
  //         checkInDate,
  //         checkOutDate,
  //         importantOrgId,
  //         1,
  //         limit
  //       );

  //       const mappedData = propertiesResponse.data.map(property => {
  //         const calendarItem = calendarResponse.data?.reservations.find(
  //           (cd: ListingType) => cd.calendarPropertyId === property.id
  //         );
  //         return {
  //           calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
  //           calendarPropertyId: property.id,
  //           propertyAddress: property.address,
  //           propertyTitle: property.name,
  //           jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
  //         };
  //       });

  //       setCalendarData(mappedData as ListingType[]);
  //       setFilteredProperties(propertiesResponse.data);
  //       setPropertyCount(propertiesResponse.data.length);
  //     } else {
  //       setProperties([]);
  //       setCalendarData([]);
  //       setFilteredProperties([]);
  //       setPropertyCount(0);
  //     }
  //   } catch (error) {
  //     console.error('Error updating properties', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const updateProperties = async (checkInDate: string, checkOutDate: string, city: string) => {
    try {
      setLoading(true);
      setIsFiltered(true);
      setSelectedRange({ startDate: checkInDate, endDate: checkOutDate });
      setSpecificDate(checkOutDate);
      setNeedToScrollToFirstDate(true);
      const calendarResponse = await fetchCalendarDataVFour(
        checkInDate,
        checkOutDate,
        importantOrgId,
        1,
        limit,
        city
      );

      if (calendarResponse.data) {
        const { properties = [], reservations = [] } = calendarResponse.data;

        const mappedData = properties.map(property => {
          const calendarItem = reservations.find(cd => cd.calendarPropertyId === property.id);
          return {
            calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
            calendarPropertyId: property.id,
            propertyAddress: property.address,
            propertyTitle: property.name,
            jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          };
        });

        setCalendarData(mappedData as ListingType[]);
        setFilteredProperties(properties);
        setProperties(properties);
        setPropertyCount(properties.length);
        if (!calendarResponse.data.properties) {
          setNeedToScrollToFirstDate(true);
        } else {
          setNeedToScrollToSpecificDate(true);
        }
      } else {
        setProperties([]);
        setCalendarData([]);
        setFilteredProperties([]);
        setPropertyCount(0);
        setNeedToScrollToSpecificDate(false);
        setNeedToScrollToFirstDate(true);
      }
    } catch (error) {
      console.error('Error updating properties', error);
    } finally {
      setLoading(false);
    }
  };


  const displayedProperties = React.useMemo(() => {
    if (!searchTerm) return filteredProperties;
    return filteredProperties.filter(property =>
      property?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, filteredProperties]);

  return (
    <>
      <div className="multicalendar-header-container">
        <div className="search-listings">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search Listings"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
        </div>
        <div className="calendar-header">
          <div className="date-filter-container">
            <div className="date-filter">
              <button className="navi-btn" onClick={() => handleMonthChange('prev')} disabled={loading || isFetchingMore}>
                <img src={leftIcon} alt="left-button" className='left-icon' />
              </button>
              <span className="mx-3">{currentMonth}</span>
              <button className="navi-btn" onClick={() => handleMonthChange('next')} disabled={loading || isFetchingMore}>
                <img src={rightIcon} alt="right-button" className='right-icon' />
              </button>
            </div>
            <div>
              <button className="secondary-btn ml-24" onClick={handleTodayClick} disabled={loading || isFetchingMore}>
                Today
              </button>
            </div>
          </div>
          <div className="another-button">
            {isFiltered && (
              <button className="secondary-btn mr-8" onClick={resetFilter}>
                Reset
              </button>
            )}
            <button className="primary-btn" onClick={toggleModal}>
              Availability
            </button>
          </div>
        </div>
      </div>

      <div className="multicalendar-container">
        <div className="listings">
          <div className="listings-header">
            {propertyCount} Properties {searchTerm ? 'Found' : 'Active'}
          </div>
          {displayedProperties.map(property => (
            <Listing key={property.id} listing={property} />
          ))}
        </div>
        <Calendar
          ref={calendarRef}
          listings={calendarData.filter(cd =>
            displayedProperties.some(fp => fp.id === cd.calendarPropertyId)
          )}
          dates={dates}
          properties={properties}
          loading={loading}
          onBookingClick={openBookingSidebar}
          onJobClick={openJobSidebar}
          onReservationClick={openReservationSidebar}
          onJobDetailClick={openJobDetailSidebar}
          selectedRange={selectedRange}
        />

      </div>
      {isFetchingMore && (
        <div className="fetching-more-indicator">
          <div className="spinner-message"></div>
          <div>Please wait, loading more data for this month...</div>
        </div>)
      }
      {isModalOpen && <FindAvailabilityModal onClose={toggleModal} updateProperties={updateProperties} />}
      {/* Sidebars */}
      {isBookingSidebarOpen && (
        <BookingSidebar
          reservationId={selectedBooking?.reservationPMS?.id}
          guestName={selectedBooking?.reservationPMS?.guestFullName}
          isManualBlock={selectedBooking?.reservationPMS?.otaName === 'Manual Block'}
          isAdvanceNotice={selectedBooking?.reservationPMS?.otaName === 'Advance Notice'}
          isAfterBlock={selectedBooking?.reservationPMS?.otaName === 'After Block'}
          isBeforeBlock={selectedBooking?.reservationPMS?.otaName === 'Before Block'}
          isRollingWindow={selectedBooking?.reservationPMS?.otaName === 'Rolling window'}
          arrivalDate={selectedBooking?.reservationPMS?.arrivalDate}
          departureDate={selectedBooking?.reservationPMS?.departureDate}
          onClose={closeBookingSidebar}
          sideBar={isBookingSidebarOpen}
          propertyPassed={selectedProperty}
        />
      )}

      {
        isJobSidebarOpen && (
          <JobSidebar
            selectedDate={selectedDate}
            onClose={closeJobSidebar}
            sideBar={isJobSidebarOpen}
            property={selectedProperty}
          />
        )
      }
      {
        isReservationSidebarOpen && (
          <ReservationSidebar
            onClose={closeReservationSidebar}
            sideBar={isReservationSidebarOpen}
            selectedDate={selectedDate}
            property={selectedProperty}
          />
        )
      }

      {
        isJobDetailSidebarOpen && selectedJob && (
          <JobDetailSidebar
            onClose={closeJobDetailSidebar}
            sideBar={isJobDetailSidebarOpen}
            jobDetail={selectedJob}
            property={selectedProperty}
          />
        )
      }
    </>
  );
};

export default CalendarApp;



